<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">자유 게시판</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <v-text-field
            class="form-inp lg ml-2"
            outlined
            hide-details
            label="제목"
            v-model="txtFldTitleV"
          />
          <v-text-field
            class="form-inp sm ml-2"
            outlined
            hide-details
            label="등록자"
            v-model="txtFldRegV"
          />
        </div>
        <div class="d-flex align-center ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" class="jh-btn is-fill" outlined @click="clickGetList"> 조회 </v-btn>
        </div>
      </div>
    </div>
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        게시물 리스트
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" class="btn-default" outlined @click="clickNewWrite"> 신규 </v-btn>
        </div>
      </h2>

      <div class="box-ct">
        <v-data-table
          class="grid-default case03"
          height="549px"
          dense
          fixed-header
          :headers="gridListDataHeaders"
          :items="gridListDataText"
          :items-per-page="30"
          item-key="id"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          @dblclick:row="dbClickListRow"
          @click:row="rowClick"
          single-select
          v-model="gridListItemSelected"
        >
          <template v-slot:item.BRD_TIT="{ item }" >
            <td>
              <!-- 댓글, 새글 아이콘 표시 -->
              <span v-html="item.NBSP"></span>
              <v-icon color="#EF9191" v-if="item.LVL_NO > 0" size="18px">
                mdi-subdirectory-arrow-right
              </v-icon>
              {{ item.BRD_TIT }}
              <v-icon
                color="#BA6EFF"
                v-if="item.BRD_TIT_NEW == 'Y'"
                size="18px"
              > mdi-alpha-n-box
              </v-icon>

              <!-- 첨부파일이 있을 경우 아이콘 표시 -->
              <v-icon  v-if="item.FILE_CNT > 0" size="18px" small class="svg-clip-file" align="center"></v-icon>
            </td>
          </template>          
        </v-data-table>
              
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>

    <!-- 새글 쓰기 모달창 -->
    <div class="ml-auto">
      <v-dialog
        v-if="DialogM6101P01"
        persistent
        v-model="DialogM6101P01"
        content-class="square-modal"
        max-width="1400px"
      >
        <dialog-M6101P01
          headerTitle="새글 등록"
          @hide="hideDialog('M6101P01')"
          @Qsave="clickGetList(), hideDialog('M6101P01')"
          :param="M6101P01Param"
        >
        </dialog-M6101P01>
        <template v-slot:body> </template>
      </v-dialog>

      <v-dialog
        v-if="DialogM6101P02"
        persistent
        v-model="DialogM6101P02"
        content-class="square-modal"
        max-width="1400px"
      >
        <dialog-M6101P02
          headerTitle="상세내용보기"
          @hide="hideDialog('M6101P02')"
          @Qsave="clickGetList(), hideDialog('M6101P02')"
          :param="M6101P02Param"
        >
        </dialog-M6101P02>
        <template v-slot:body> </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin";
import api from "@/store/apiUtil";
import DialogM6101P01 from "../old/M6101P01.vue";
import DialogM6101P02 from "../old/M6101P02.vue";

export default {
  name: "MENU_M6101", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM6101P01,
    DialogM6101P02,
  },
  mixins: [mixin],
  data() {
    return {
      brdId: "2",

      DialogM6101P01: false,
      DialogM6101P02: false,
      M6101P01Param: {},
      M6101P02Param: {},

      txtFldTitleV: "",
      txtFldRegV: "",

      // 페이지 리스트
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      totalVisible: 10,

      gridListItemSelected: [],
      gridListDataSelect: [],
      gridListDataText: [],
      gridListDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "80px",
          sortable: false,
        },
        {
          text: "제목",
          value: "BRD_TIT",
          align: "center",
          width: "650px",
          sortable: false,
        },
        {
          text: "파일수",
          value: "FILE_CNT",
          align: "center",
          width: "70px",
          sortable: false,
        },
        {
          text: "등록자",
          value: "FST_USER_NM",
          align: "center",
          width: "130px",
          sortable: false,
        },
        {
          text: "등록일시",
          value: "FST_BRD_DT",
          align: "center",
          width: "230px",
          sortable: true,
        },
        {
          text: "수정일시",
          value: "LAST_BRD_DT",
          align: "center",
          width: "230px",
          sortable: true,
        },
        {
          text: "조회수",
          value: "SELECT_NO",
          align: "center",
          width: "80px",
          sortable: true,
        },
      ],

      alertMsg: {
        searchChk: "제목 또는 등록자를 입력해 주세요.",
        selBoxIdChk: "게시판명 은(는) 필수입력입니다.",
        noCntData: "조회된 데이터가 없습니다.",
        newWriteChk: "조회 후 작성 가능합니다.",
        responseError: "시스템에 오류가 발생하였습니다.",
      },
    };
  },
  
  // 화면 오픈시 리스트 조회
  created() {
    this.refreshGridList();
  },

  methods: {

    // 조회버튼 클릭 데이터 조회
    clickGetList() {
      if (this.txtFldTitleV == "" && this.txtFldRegV == "") {
        this.common_alert(this.alertMsg.searchChk, "error");
        return;
      } 
      this.getGridList();
    },

    // 화면 오픈시 리스트 조회
    async refreshGridList() {
      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/board/list";
      requestData.headers["SERVICE"] = "board";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["BRD_ID"] = "2";
      requestData.sendData["ASP_NEWCUST_KEY"] = "1040";
      requestData.sendData["BULTN_FR_DT"] = "";
      requestData.sendData["BULTN_TO_DT"] = "";
      requestData.sendData["NOTICE_STATUS"] = "";

      let responseGridList = await this.common_postCall(requestData);
      let resHeader = responseGridList.HEADER;
      let resData = responseGridList.DATA;

      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG == true) {
        this.common_alert(this.alertMsg.responseError, "error");
        return false;
      }

      if (resData != 0) {
        let idx = 1;
        for (let i in resData) {
          let id = resData[i].BRD_NO;
          resData[i]["id"] = id;
          resData[i]["index"] = idx++;
          resData[i]["FST_BRD_DT"] = this.parseDateTime(
            resData[i]["FST_BRD_DT"]
          );
          resData[i]["LAST_BRD_DT"] = this.parseDateTime(
            resData[i]["LAST_BRD_DT"]
          );

          if (
            resData[i]["FILE_CNT"] == "" ||
            resData[i]["FILE_CNT"] == "undefined" ||
            resData[i]["FILE_CNT"] == "0"
          ) {
            resData.file = false;
          } else {
            resData.file = true;
          }
        }
      }

      this.gridListDataText = resData;
    },

    // 조회버튼 클릭 데이터 호출     
    async getGridList() {
      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/board/list";
      requestData.headers["SERVICE"] = "board";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["BRD_ID"] = "2";
      requestData.sendData["ASP_NEWCUST_KEY"] = "1040";
      requestData.sendData["BRD_TIT"] = this.txtFldTitleV;
      requestData.sendData["FST_USER_NM"] = this.txtFldRegV;
      requestData.sendData["LAST_USER_NM"] = this.txtFldModV;
      requestData.sendData["BULTN_FR_DT"] = "";
      requestData.sendData["BULTN_TO_DT"] = "";
      requestData.sendData["NOTICE_STATUS"] = "";

      let responseGridList = await this.common_postCall(requestData);
      let resHeader = responseGridList.HEADER;
      let resData = responseGridList.DATA;

      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG == true) {
        this.common_alert(this.alertMsg.responseError, "error");
        return false;
      }

      if (resData != 0) {
        let idx = 1;
        for (let i in resData) {
          let id = resData[i].BRD_NO;
          resData[i]["id"] = id;
          resData[i]["index"] = idx++;
          resData[i]["FST_BRD_DT"] = this.parseDateTime(
            resData[i]["FST_BRD_DT"]
          );
          resData[i]["LAST_BRD_DT"] = this.parseDateTime(
            resData[i]["LAST_BRD_DT"]
          );

          if (
            resData[i]["FILE_CNT"] == "" ||
            resData[i]["FILE_CNT"] == "undefined" ||
            resData[i]["FILE_CNT"] == "0"
          ) {
            resData.file = false;
          } else {
            resData.file = true;
          }
        }
      }
      this.gridListDataText = resData;
    },

    clickNewWrite() {
      this.M6101P01Param = {
        flag: "new",
        userId: this.$store.getters["userStore/GE_USER_ID"],
        item: {
          BRD_ID: "2",
        },
        BRD_ID: "2",
      };

      this.showDialog("M6101P01");
    },

    // row 더블 클릭이벤트
    dbClickListRow(e, { item }) {
      this.M6101P02Param = {
        userId: this.$store.getters["userStore/GE_USER_ID"],
        listData: this.gridListDataText,
        item: item,
      };

      this.showDialog("M6101P02");
    },

    parseDateTime(data) {
      let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join(
        "-"
      );
      let hour = [
        data.substr(8, 2),
        data.substr(10, 2),
        data.substr(12, 2),
      ].join(":");

      return year + " " + hour;
    },

    showDialog(menu) {
      console.log("showDialog");
      this[`Dialog${menu}`] = true;
    },

    hideDialog(menu) {
      console.log("hideDialog");
      this[`Dialog${menu}`] = false;
      this.getGridList();
    },

    rowClick: function (item, row) {
      row.select(true);
    },
  },

  mounted() {
  },

  computed: {
  },

  watch: {
  },
};
</script>

<style></style>
